import React from "react";
import { graphql } from "gatsby";
import { Container, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { Layout, Seo } from "../../components";

const TranslationGuidePage = ({ data, ...rest }) => (
  <Layout {...rest}>
    <Seo
      location="articles/translation-guide"
      title="seoArticleTranslationGuidePageTitle"
    />
    <Container fluid="lg" className="py-4 py-md-5">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <h1>
            <FormattedMessage
              id="articleTranslationGuideTitle"
              defaultMessage="How to translate YearCompass"
            />
          </h1>
          <p>
            <FormattedMessage
              id="articleTranslationGuideParagraph1"
              defaultMessage="Found a mistake in an existing translation? Want to bring YearCompass to a whole new language? Either way, you've come to the right place!"
            />
          </p>

          <p>
            <FormattedMessage
              id="articleTranslationGuideInviteText"
              defaultMessage="We use the Locize app to manage translations of the YearCompass booklet. You can join as a translator for <b>your currently selected locale</b> here:"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </p>

          <p className="text-center">
            <Button
              href={data?.locale?.translatorInviteLink}
              variant="secondary"
              className="font-weight-bold"
            >
              <FormattedMessage
                id="articleTranslationGuideInviteButton"
                defaultMessage="Join as Translator"
              />
            </Button>
          </p>

          <p>
            <FormattedMessage
              id="articleTranslationGuideInviteLinkListText"
              defaultMessage="Alternatively, you can pick your translation language from the list below:"
            />
          </p>

          <ul className="column-count-3">
            {data?.allLocale.edges?.map(
              ({ node }) =>
                node.translatorInviteLink && (
                  <li key={node.fullLocaleCode}>
                    <a href={node.translatorInviteLink}>
                      <i className={`flag-icon flag-icon-${node.flagCode}`} />{" "}
                      {node.languageName}
                    </a>
                  </li>
                )
            )}
          </ul>

          <p>
            <FormattedMessage
              id="articleTranslationGuideParagraphInviteEmail"
              defaultMessage="If the above links don't work for you, please send us an email to <a>hello@yearcompass.com</a>."
              values={{
                a: (chunks) => (
                  <a href="mailto:hello@yearcompass.com">{chunks}</a>
                ),
              }}
            />{" "}
            <FormattedMessage
              id="articleTranslationGuideParagraph3"
              defaultMessage="Let us know which language you would like to create or edit, and we'll guide you through the rest."
            />
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default TranslationGuidePage;

export const query = graphql`
  query ($id: String) {
    locale(id: { eq: $id }) {
      translatorInviteLink
    }
    allLocale(sort: { fields: languageName }) {
      edges {
        node {
          fullLocaleCode
          translatorInviteLink
          languageName
          flagCode
        }
      }
    }
  }
`;
